/**
 * @description 基座布局完成埋点上报
 * @module types
 */
import { onMounted } from 'vue';
import { perfAppLayoutMounted } from '@/services/performance/lifecycles';


export function useMountedReport(): void {
  onMounted(() => {
    /**
     * 结束首屏预加载信息展示
     * 1：停止Intervla
     * 2：停止PerformanceObserver
     */
    window.loadingPre?.destory();
    if (!window.aegisSDK) return;
    perfAppLayoutMounted();
  });
}
