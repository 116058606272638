/**
 * @description 全局通信 API
 * @module global-api
 */
import extendedAPI from '@version-inject/services/global-api';
import type { GlobalAPI } from './index.types';
import user from './modules/user';
import router from './modules/router';
import navigation from './modules/navigation';
import utils from './modules/utils';
import micro from './modules/micro-app';
import auth from './modules/authorize';
import performance from './modules/performance';
import globalPopupSlot from './modules/global-popup-slot';
import { patchGlobalAPI } from './utils/patch-global-api';
import { injectJS } from '@/entry';

// 合并所有模块方法
const globalAPI: GlobalAPI = {
  user,
  router,
  navigation,
  utils,
  micro,
  auth,
  performance,
  globalPopupSlot,
};

injectJS().then((res) => {
  if (!res) return;
  // 通过动态扩展注入
  res.patchGlobalAPI(globalAPI);
});

export default patchGlobalAPI(globalAPI, extendedAPI);
