/**
 * @description
 * @module types
 */
import { useGlobalPopupSlot } from './use-global-popup-slot';

// 控制台调试时，监听事件，打开弹窗
const registerDebuggerService = function registerDebuggerService(): void {
  const { open } = useGlobalPopupSlot();
  addEventListener('open-global-popup', (e: any) => {
    const { options, payload } = e?.detail;
    open(options, payload);
  }, false);
};

registerDebuggerService();
