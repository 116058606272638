/**
 * @description 顶部导航 API
 * @module global-api
 */
import type {
  DropDownSetting,
  EnableSpaceSwitchOpts,
  GuideOpts,
  PrimaryNavOpts,
  SpaceBottomActionOpts,
} from '@/stores/navigation/navigation.types';
import { NavigationStoreState, useNavigationStore } from '@/stores/navigation';
import { useMicroAppStore } from '@/stores/micro-app';
import { LocatedOrgReq } from '@/types/apis/organisation';
import eventCenter from '../event/eventCenter';
import { EventCenterObserverCallback, NavigationAPI } from '../index.types';

enum EventType {
  // 与 StoreID 对应
  StateChange = 'navigationStore',
  // 点击一级导航
  ClickPrimaryNav = 'navigation:clickPrimaryNav',
  // 点击引导
  ClickGuide = 'navigation:clickGuide',
  // 点击下拉设置
  ClickDropDownSetting = 'navigation:clickdropdownsetting',
  // 点击空间切换底部按钮
  ClickSpaceBottomAction = 'navigation:clickSpaceBottomAction',
  // 点击Logo
  ClickLogo = 'navigation:clicklogo',
}

/**
 * 状态变更事件
 * @param callback
 */
const onStateChange = function onStateChange(callback: EventCenterObserverCallback): void {
  // user需与pinia下管理的store的storeId对应
  eventCenter.on(EventType.StateChange, callback);
};

/**
 * 配置顶部导航
 * @param options {NavigationStoreState}
 * @param deepMerge {boolean}
 */
const setTopNavigation = function setTopNavigation(options: NavigationStoreState, deepMerge?: boolean): void {
  useNavigationStore().setTopNavigation(options, deepMerge);
};

/**
 * 一级导航点击事件
 * @param callback {EventCenterObserverCallback<PrimaryNavOpts>}
 */
const onClickPrimaryNav = function onClickPrimaryNav(callback: EventCenterObserverCallback<PrimaryNavOpts>): void {
  eventCenter.on(EventType.ClickPrimaryNav, callback);
};

/**
 * 触发一级导航激活
 * @param currentNav {PrimaryNavOpts}
 */
const dispatchClickPrimaryNav = function dispatchClickPrimaryNav(currentNav: PrimaryNavOpts): void {
  eventCenter.dispatch(EventType.ClickPrimaryNav, currentNav);
};

/**
 * 监听引导被点击
 * @param callback {EventCenterObserverCallback<GuideOpts>}
 */
const onClickGuide = function onClickGuide(callback: EventCenterObserverCallback<GuideOpts>): void {
  eventCenter.on(EventType.ClickGuide, callback);
};

/**
 * 触发点击引导
 * @param currentGuide {GuideOpts}
 */
const dispatchClickGuide = function dispatchClickGuide(currentGuide: GuideOpts): void {
  eventCenter.dispatch(EventType.ClickGuide, currentGuide);
};

/**
 * 监听头像下拉功能区域被点击
 * @param callback {EventCenterObserverCallback<DropDownSetting>}
 */
const onClickDDownSetting = function onClickDDownSetting(callback: EventCenterObserverCallback<DropDownSetting>): void {
  eventCenter.on(EventType.ClickDropDownSetting, callback);
};

/**
 * 触发点击下拉设置
 * @param currentGuide {DropDownSetting}
 */
const dispatchDDownSetting = function dispatchDropDownSetting(current: DropDownSetting): void {
  eventCenter.dispatch(EventType.ClickDropDownSetting, current);
};

/**
 * 更新用户当前所在组织/空间
 * @param locatedOrgOpts {LocatedOrgReq}
 */
const updateUserCurrentOrgs = function updateUserCurrentOrgs(locatedOrgOpts: LocatedOrgReq): void {
  useNavigationStore().updateUserCurrentOrgs(Object.assign({
    appKey: useMicroAppStore().currentAppIdBE,
  }, locatedOrgOpts));
};

/**
 * 监听左上角logo被点击，如有
 * @param callback {EventCenterObserverCallback}
 */
const onClickLogo = function onClickLogo(callback: EventCenterObserverCallback): void {
  eventCenter.on(EventType.ClickLogo, callback);
  useNavigationStore().setTopNavigation({
    ifClickHeaderLogo: true,
  });
};

/**
 * 触发点击logo
 * @param currentGuide {GuideOpts}
 */
const dispatchClickLogo = function dispatchClickGuide(): void {
  eventCenter.dispatch(EventType.ClickLogo);
};

// 空间切换组件相关事件
const spaceSwitchEvent = {
  dispatchSpaceBottomAction(current: SpaceBottomActionOpts) {
    eventCenter.dispatch(EventType.ClickSpaceBottomAction, current);
  },
  onClickSpaceBottomAction(callback: EventCenterObserverCallback<SpaceBottomActionOpts>) {
    eventCenter.on(EventType.ClickSpaceBottomAction, callback);
  },
  enableSpaceSwitch(spaceSwitchOpts: EnableSpaceSwitchOpts | undefined) {
    useNavigationStore().enableSpaceSwitch(spaceSwitchOpts);
    if (!spaceSwitchOpts?.userCurrentOrgs) return;
    updateUserCurrentOrgs(spaceSwitchOpts.userCurrentOrgs);
  },
  disableSpaceSwitch() {
    useNavigationStore().disableSpaceSwitch();
  },
};

const navigationAPI: NavigationAPI = {
  onStateChange,
  setTopNavigation,
  onClickPrimaryNav,
  dispatchClickPrimaryNav,
  updateUserCurrentOrgs,
  onClickGuide,
  dispatchClickGuide,
  onClickDDownSetting,
  dispatchDDownSetting,
  onClickLogo,
  dispatchClickLogo,
  ...spaceSwitchEvent,
};

export default navigationAPI;
