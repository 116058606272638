/*
 * @Author: kelvinywang
 * @Date: 2022-05-17 16:28:01
 * @LastEditors: xqbzheng
 * @LastEditTime: 2022-10-17 11:05:40
 * @Description: 前置路由守卫
 */
import type { RouteLocationNormalized } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { NO_NEED_VERIFY_USER, NO_NEED_VERIFY_CORP } from '@/constants/route';
import { useMicroAppStore } from '@/stores/micro-app';
import { useNavigationStore } from '@/stores/navigation';

const getAppRouteOptsByHref = (to: RouteLocationNormalized): MicroApp.RouteComponentOpts | undefined => {
  const currentApp = window.FrontConfig.microApps.find(app => app.id === to.name);
  if (!currentApp || !currentApp.routeComponents) return;
  return currentApp.routeComponents.find(rc => new RegExp(rc.urlMatchReg).test(location.href));
};

/**
 * 校验当前应用是否需要鉴权
 * @param to
 * @returns
 */
const needAuthCurrentApp = function needAuthCurrentApp(to: RouteLocationNormalized): boolean {
  return !getAppRouteOptsByHref(to)?.noAuthentication;
};

/**
 * 设置顶部导航
 * @param to
 * @returns
 */
const setNavigation = function setNavigation(to: RouteLocationNormalized): void {
  const appRouteOpts = getAppRouteOptsByHref(to);
  if (!appRouteOpts?.navigation) return;
  useNavigationStore().setTopNavigation(appRouteOpts.navigation);
};

const verifyHistoryStateCurentPrefix = function verifyHistoryStateCurentPrefix(
  baseroute: string,
  current: string,
): boolean {
  return new RegExp(`^${baseroute}`).test(current);
};

/**
 * 监听应用内所有路由变化
 * 处理 history.state 内记录 未带子应用前缀的，用于浏览器会退
 * @param to
 * @param from
 * @returns
 */
const handleRouterHistory = function handleRouterHistory(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): void {
  const microAppStore = useMicroAppStore();
  const fromApp = microAppStore.getAppConfig(from.name as MicroApp.AppKeyType);
  if (!fromApp) return;
  // 注意：这里的 currentApp 为进入该路由前的
  const { baseroute } = fromApp;
  // 如果当前路由不存在 baseroute 直接返回
  if (!baseroute) return;
  const historyStateCurrent = history.state.current || '';
  const allBaseRoutes = microAppStore.allApps.map(app => app.baseroute);
  // 判读历史 state.current 是否包含一个子应用，避免出现 /console/datatalk 这种情况
  // 注意这里需要判断 scopeBaseRoute 是否存在，不存在就返回 fasle
  const existStateCurrent = allBaseRoutes.some(scopeBaseRoute => scopeBaseRoute
    && verifyHistoryStateCurentPrefix(scopeBaseRoute, historyStateCurrent));
  if (existStateCurrent || verifyHistoryStateCurentPrefix(baseroute, historyStateCurrent)) return;
  history.state.current = `${baseroute}${historyStateCurrent}`;
};

/**
 * 处理租户信息
 * 当租户信息不存在时，请求后端获取租户信息
 * @returns {boolean}
 */
const handleCorpInfo = async function handleCorpInfo(to: RouteLocationNormalized): Promise<boolean> {
  // 不需要校验用户登陆直接放过
  if (NO_NEED_VERIFY_USER.some(item => item.test(to.path))) return true;
  // 校验当前应用是否需要鉴权
  if (!needAuthCurrentApp(to)) return true;
  const userStore = useUserStore();
  // 未获取用户时拉取用户信息
  if (!userStore.user.userId) {
    // 这里不做阻塞
    userStore.fetchUserInfo();
  }
  // 不需要校验企业信息直接放过
  if (NO_NEED_VERIFY_CORP.some(item => item.test(to.path))) return true;
  if (userStore.corpId) return true;
  // 初始化租户信息，阻塞，其内部增加了缓存逻辑
  await userStore.initCorpId();
  return true;
};

/**
 * 进入子应用
 * @param to
 */
const handleEnterSubApp = async function handleEnterSubApp(to: RouteLocationNormalized): Promise<void> {
  // microapp store
  const microAppStore = useMicroAppStore();
  await microAppStore.enterSubApp(to.name as MicroApp.AppKeyType);
};

const handleBeforeEach = async function handleBeforeEach(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): Promise<boolean> {
  handleRouterHistory(to, from);
  setNavigation(to);
  await handleEnterSubApp(to);
  return await handleCorpInfo(to);
};

export default handleBeforeEach;
