/**
 * @description App 实例化所需的模块，在 Vue 初始化前执行
 * @module entry
 */
import '@/services/performance/observer';
import '@/services/performance/crash-detect';
import { patchGlobalHistory } from './patch-route';

patchGlobalHistory();

export * from './handle-error';
export * from './inject-js';
export * from './micro-app';
export * from './global-components';
export * from './init-front-config';
export * from './at-repoter';
export * from './pv-report';
