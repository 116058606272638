/**
 * @description 为 History 打补丁，帮助主应用监听路由变化
 * @module entry
 */

type HistoryStateKey = 'pushState' | 'replaceState';

type ReplaceStateParams = [data: unknown, unused: string, url?: string | URL | null | undefined];

// 监听 History URL 变化事件参数
interface OnHistoryUrlChangeEventData {
  oldUrl: string,
  url: string,
}
export const HISTORY_URL_CHANGE_EVENT_KEY = 'onHistoryUrlChange';
/**
 * 分发 History URL 变化事件
 * @param detail {OnHistoryUrlChangeEventData}
 */
const dispatchUrlChangeEvent = function dispatchHistoryUrlChangeEvent(detail: OnHistoryUrlChangeEventData): void {
  const onHistoryUrlChangeEvent = new CustomEvent(HISTORY_URL_CHANGE_EVENT_KEY, {
    detail,
  });
  document.dispatchEvent(onHistoryUrlChangeEvent);
};

export const patchGlobalHistory = function patchGlobalHistory(): void {
  // 需要补丁的 'pushState', 'replaceState' 事件
  const patchHistoryKey: HistoryStateKey[] = ['pushState', 'replaceState'];

  // 通过 proxy 给 history 'pushState', 'replaceState' 打补丁
  patchHistoryKey.forEach((changeState: HistoryStateKey) => {
    window.history[changeState] = new Proxy(window.history[changeState], {
      apply(target, thisArg, argArray: ReplaceStateParams) {
        const [,,socrpUrl] = argArray;
        const socrpUrlStr = String(socrpUrl || '');
        console.log(`changeState: ${changeState}`, argArray, location.pathname);
        // 触发 URL 变化事件
        dispatchUrlChangeEvent({
          url: socrpUrlStr,
          oldUrl: location.pathname,
        });
        return target.apply(thisArg, argArray);
      },
    });
  });

  // popstate
  window.addEventListener('popstate', ({ state }) => {
    dispatchUrlChangeEvent({
      url: state.current,
      oldUrl: location.pathname,
    });
  });
};
