/**
 * @description 微前端 API
 * @module global-api
 */
import { cloneDeep } from 'lodash-es';
import { AppKeyMapping, BEAppKeyType } from '@/constants/micro';
import { useMicroAppStore } from '@/stores/micro-app';
import transformAppId from '../utils/transform-app-key';

export interface MicroAPI {
  getAppConfig(anyAppKey: MicroApp.AppKeyType | BEAppKeyType): MicroApp.AppOpts | undefined;
  getAppKey(beAppKey: BEAppKeyType): MicroApp.AppKeyType;
  getBEAppKey(appKey: MicroApp.AppKeyType): BEAppKeyType;
}

const microAPI: MicroAPI = {
  /**
   * 获取子应用配置信息
   * @param anyAppKey
   * @returns
   */
  getAppConfig(anyAppKey) {
    const { appKey } = transformAppId(anyAppKey);
    return cloneDeep(useMicroAppStore().getAppConfig(appKey));
  },
  /**
   * 获取前端 AppKey
   * @param scropeBeAppKey 后端 AppKey
   * @returns
   */
  getAppKey(scropeBeAppKey) {
    const { appKey } = transformAppId(scropeBeAppKey);
    return appKey;
  },
  /**
   * 获取后端 AppKey
   * @param appKey 前端 AppKey
   * @returns
   */
  getBEAppKey(appKey) {
    return AppKeyMapping[appKey];
  },
};

export default microAPI;
