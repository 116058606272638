/**
 * @description 路由相关注入点
 * @module router
 */

import type { RouteRecordRaw } from 'vue-router';

/**
 * Patch 路由
 * @param defatulRoutes
 * @returns
 */
export const patchRoutes = function patchRoutes(defatulRoutes: RouteRecordRaw[]): RouteRecordRaw[] {
  return defatulRoutes;
};
