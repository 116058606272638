/**
 * @description 入口文件
 * @module types
 */
import '@/assets/svg';
import '@/assets/style';
import { createApp } from 'vue';
// 注入路由执行后的逻辑需要放到外部，如果放到 @/entry 内会导致 import 顺序异常
// TODO: 后续腾讯云版本插件化后移到插件内
import '@version-inject/entry/patch-route-after';
import {
  handleError,
  handleGlobalComponents,
  initFrontConfig,
  initMicroApp,
  injectJS,
  atSubscribeStore,
  subscriPvReport,
} from '@/entry';
import App from '@/app.vue';
import $router from '@/router';
import pinia from '@/stores';
import { perfAppInit } from './services/performance/lifecycles';

const initApp = async function initApp(): Promise<void> {
  // 应用初始化性能埋点
  perfAppInit();
  // 执行 JS 注入
  await injectJS();
  const app = createApp(App)
    .use($router.initRouter())
    .use(pinia);
  // 全局错误处理
  handleError(app);
  // 全局组件
  handleGlobalComponents(app);
  // 初始化微前端框架
  initMicroApp();
  // 初始化 FrontConfig
  initFrontConfig();
  atSubscribeStore();
  subscriPvReport();
  // 挂载 vue
  app.mount('#dtkit-app');
};

// FrontConfig 在 index.html 前置请求，不阻塞 JS 资源加载
// 由于 FrontConfig 中存在路由配置，需要等到配置返回再初始化 Vue
if (window.FrontConfig) {
  initApp();
} else {
  window.SuitePreFetch?.getFrontendCommonConfig?.then(() => {
    initApp();
  }).catch((err) => {
    console.error('getFrontendCommonConfig 请求获取失败', err);
  });
}
