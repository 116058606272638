/**
 * @description microapp 框架全局生命周期处理
 * @module types
 */
import type { lifeCyclesType } from '@micro-app/types';
import { getAllApps } from '@micro-zoe/micro-app';
import { MicroAppLoadStatus } from '@/services/performance/constant';
import {
  perfMAppBeforeMounted,
  perfMAppCreated,
  perfMAppMounted,
} from '@/services/performance/lifecycles';

// 该应用是否加载过
let verifyAppCacheFlag: MicroAppLoadStatus = MicroAppLoadStatus.Initial;

/**
 * 校验应用是否加载过
 * @param appName
 * @returns
 */
const verifyAppCache = function verifyAppCache(appName: string): MicroAppLoadStatus {
  const allApps = getAllApps();
  if (!allApps.length) return MicroAppLoadStatus.Initial;
  if (allApps.includes(appName)) return MicroAppLoadStatus.Loaded;
  return MicroAppLoadStatus.First;
};

export const lifeCycles: lifeCyclesType = {
  created(e) {
    const appName = e.detail.name as MicroApp.AppKeyType;
    verifyAppCacheFlag = verifyAppCache(e.detail.name);
    perfMAppCreated(verifyAppCacheFlag, appName);
  },
  beforemount(e) {
    const appName = e.detail.name as MicroApp.AppKeyType;
    perfMAppBeforeMounted(verifyAppCacheFlag, appName);
  },
  mounted(e) {
    const appName = e.detail.name as MicroApp.AppKeyType;
    perfMAppMounted(verifyAppCacheFlag, appName);
  },
  unmount() {
    // '[MICROAPP] unmount'
  },
  error() {
    // [MICROAPP] error
  },
  beforeshow() {
    // [MICROAPP] beforeshow
  },
  aftershow() {
    // [MICROAPP] aftershow
  },
  afterhidden() {
    // [MICROAPP] afterhidden
  },
};
