/**
 * @description 处理全局错误
 * @module entry
 */
import type { App } from 'vue';

export const handleError = function handleError(app: App): void {
  app.config.errorHandler = (err: any, vm, info) => {
    // 处理错误
    // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
    const errString = err?.toString?.();
    const errStack = err?.stack;
    console.log(`Error: ${errString}\nStack: ${errStack}\nInfo: ${info}`);
    window.aegisSDK?.error(`Error: ${errString}\nStack: ${errStack}\nInfo: ${info}`);
  };
};
