/**
 * @description 请求
 * @module request
 */
import { http, AxiosResponse } from '@suite/shared';
import { IResponse } from '@suite/shared/src/http/http';
import globalAPI from '@/services/global-api';

// 请求接收拦截器
http.axiosInstance.interceptors.response.use((res: AxiosResponse<IResponse>) => res, (err) => {
  if (err.response?.status === 401) {
    globalAPI.auth.login();
  }
  // 这里必须将错误抛出，不然调用方无法 catch 错误信息
  return Promise.reject(err);
});

export default http;
