/**
 * @description 探测浏览器 tab crash 上报
 * @module entry
 */

import { PerfSuitePointEnum } from './constant';

// localStorage 中的 key
const LOCAL_STORAGE_PREFIX = 'BEACON_SUITE_CRASH_DETECT_v2:';
// 更新时间为 2分钟
const SESSION_REFRESH_INTERVAL = 2 * 60 * 1000;
// 若超过 1.5倍更新时间，则认为该 tab crash
const CRASH_DETECT_TIMEOUT = 1.5 * SESSION_REFRESH_INTERVAL;

// 通过定期更新和检查 localStorage 发现浏览器 tab crash
class CrashDetector {
  // 检查是否为合法 sessionId
  static checkSessionId(sessionId?: string | number | boolean): sessionId is string | number {
    if (typeof sessionId === 'boolean') {
      return false;
    }
    return !!sessionId;
  }

  public timer = 0;

  constructor(public sessionId: string | number) {
    // 页面可见性发生变化，重新设置监控
    document.addEventListener('visibilitychange', () => {
      this.reset();
    });
    // 退出页面清理相关状态
    window.addEventListener('unload', () => {
      this.clear();
      this.sessionId = '';
    });
  }

  // 关闭监控
  clear(): void {
    if (this.timer) {
      window.clearInterval(this.timer);
      this.timer = 0;
    }
    localStorage.removeItem(this.createStorageKey());
  }

  // 重新检查是否应该启动监控
  reset(): void {
    if (document.visibilityState === 'hidden' || !this.sessionId) {
      return this.clear();
    }
    if (this.timer) {
      return;
    }
    this.refershSelf();
    this.checkPeer();
    this.timer = window.setInterval(() => {
      this.refershSelf();
      this.checkPeer();
    }, SESSION_REFRESH_INTERVAL);
  }

  // 获取 StorageKey
  private createStorageKey(sessionId: string | number = this.sessionId): string {
    return `${LOCAL_STORAGE_PREFIX}${sessionId}`;
  }

  // 解析 StorageKey 获取 SessionId
  private extractSessionId(storageKey: string): string {
    return storageKey.slice(LOCAL_STORAGE_PREFIX.length);
  }

  // 刷新当前 tab 状态，存储能被记录 crash 的时间点
  private refershSelf(): void {
    const nextUpdate = Date.now() + CRASH_DETECT_TIMEOUT;
    localStorage.setItem(this.createStorageKey(), nextUpdate.toString());
  }

  // 检查其他 tab 状态
  private checkPeer(): void {
    for (let i = 0; i < localStorage.length; i++) {
      const storageKey = localStorage.key(i);
      if (!storageKey?.startsWith(LOCAL_STORAGE_PREFIX)) {
        continue;
      }
      // 检查是否超时
      const nextUpdate = Number.parseInt(localStorage.getItem(storageKey) ?? '', 10);
      if (nextUpdate > Date.now()) {
        continue;
      }
      // 上报超时，并清理状态
      this.reportCrash(storageKey);
      localStorage.removeItem(storageKey);
    }
  }

  // 上报 crash 数据
  private reportCrash(storageKey: string): void {
    window.aegisSDK?.reportEvent({
      name: PerfSuitePointEnum.CrashDetect,
      ext1: this.extractSessionId(storageKey),
    });
  }
}

// 仅在 aegisSDK 存在的时候
if (
  process.env.VUE_APP_NODE_ENV === 'production'
  && window.AppConfig.aegisMoniterId
  && window.aegisSDK
  && CrashDetector.checkSessionId(window.aegisSDK.bean.sessionId)
) {
  new CrashDetector(window.aegisSDK.bean.sessionId).reset();
}
