/**
 * @description 导航 Store
 * @module store
 */

import { AppKeyMapping, BEAppKeyType } from '@/constants/micro';
import type { OrgUint } from '@/types/apis/organisation';
import type {
  GuideOpts,
  PrimaryNavOpts,
  SpaceSwitchOpts,
  EnableSpaceSwitchOpts,
  AvatarDropDownOpts,
  GlobalSearchOpts,
} from './navigation.types';
import { omit, isEqual, nth, merge, isNil } from 'lodash-es';
import { defineStore } from 'pinia';
import { useUserStore } from '../user';
import { LocatedOrgReq, RecentOrgUnit } from '@/types/apis/organisation';
import { fetchRecentBiz, locatedOrg, reportVisitBiz } from '@/services/apis/organisation';

export interface NavigationStoreState extends MicroApp.NavigationOpts {
  // 空间切换组件
  spaceSwitch: SpaceSwitchOpts;
  // 用户所属组织，不允许配置
  belongOrgs: RecentOrgUnit[];
  // 是否启用一级导航
  enablePrimaryNavs: boolean;
  // 一级导航配置
  primaryNavs: PrimaryNavOpts[];
  // 是否启用搜索，默认不启用
  enableSearch: boolean;
  // 引导区域配置
  guides: GuideOpts[];
  // 头像下拉面板
  avatarDropDown: AvatarDropDownOpts;
  // 子应用上报的用户组织架构
  reportLocatedOrg?: LocatedOrgReq | null;
  // 全局搜索框配置
  globalSearchOpts: GlobalSearchOpts;
  // 顶部左侧 logo 是否可点击
  ifClickHeaderLogo: boolean;
}

export const useNavigationStore = defineStore('navigationStore', {
  state: (): NavigationStoreState => ({
    enableSideBar: true,
    enableNavigation: true,
    spaceSwitch: {
      enable: true,
      canEntrySpaceSettings: true,
      bottomActions: [
        {
          id: 'create',
          title: '新建空间',
          icon: 'add-rectangle',
        },
        {
          id: 'all',
          title: '全部空间',
          icon: 'layers',
        },
      ],
    },
    belongOrgs: [],
    enablePrimaryNavs: false,
    primaryNavs: [],
    enableSearch: false,
    globalSearchOpts: {
      placeholder: '搜索指标名称试试看',
    },
    guides: [],
    enableAvatar: true,
    avatarDropDown: {
      enable: true,
      enableDefaultSettings: true,
      customSettings: [],
    },
    ifClickHeaderLogo: false,
    reportLocatedOrg: null,
  }),
  getters: {
    ifShowSpaceSwitch: state => Boolean(state.spaceSwitch.enable && state.belongOrgs.length),
    enableAvatarDropDown: state => state.avatarDropDown.enable,
    enableCustomSettings: state => !!state.avatarDropDown.customSettings?.length,
    customSettings: state => state.avatarDropDown.customSettings,
    /**
     * @description 获取当前页面/路由下的bizId
     */
    getCurrentBizId: (state) => {
      const bizUnit = state.belongOrgs.find((unit: OrgUint) => unit.level === 'biz');
      return bizUnit ? bizUnit.id : '';
    },
  },
  actions: {
    /**
     * 设置顶部导航
     * @param options {NavigationStoreState}
     */
    setTopNavigation(options: Partial<NavigationStoreState>, deepMerge = false) {
      console.log('GlobalAPI 设置顶部导航 setTopNavigation', options);
      const sourceOptions = omit(options, [
        'belongOrgs',
        'reportLocatedOrg',
        'enableSpaceSwitch',
      ]);
      if (deepMerge) {
        merge(this, sourceOptions);
      } else {
        Object.assign(this, sourceOptions);
      }
    },
    /**
     * 设置用户所属组织
     * @param belongOrgs {RecentOrgUnit[]}
     */
    setBelongOrgs(belongOrgs: RecentOrgUnit[], appKey: BEAppKeyType | null) {
      if (isEqual(this.belongOrgs, belongOrgs)) return;
      this.belongOrgs = belongOrgs;
      if (!this.belongOrgs.length || !appKey) return;
      const leafOrg = nth(this.belongOrgs, -1);
      const secondLeafOrg = nth(this.belongOrgs, -2);
      if (!leafOrg) return;
      const userStore = useUserStore();
      // 当前空间切换时重新获取用户在当前空间下的管理员权限
      userStore.fetchOrgAdmin(appKey, leafOrg.id);
      return reportVisitBiz({
        appKey,
        bizId: leafOrg.id,
        teamId: secondLeafOrg?.id,
        teamName: secondLeafOrg?.name,
      });
    },
    /**
     * 更新用户当前所在的空间，供子应用调用
     * @param locatedOrgReq {LocatedOrgReq | null}
     */
    async updateUserCurrentOrgs(locatedOrgReq: LocatedOrgReq | null) {
      // 不重复设值
      if (isEqual(locatedOrg, this.reportLocatedOrg)) return;
      this.reportLocatedOrg = locatedOrgReq;
      // 传 null 时需要将当前设置的所属空间清除
      if (!locatedOrgReq) {
        this.setBelongOrgs([], null);
        return;
      }
      const belongOrgs = await locatedOrg(locatedOrgReq);
      // 定位企业后，判断当前用户所在企业是否一致，不一致需要更新
      // 比如：当用户通过 URL 访问 A 企业下的看板，但是最近访问的企业是 B 租户
      // for 多租户的场景
      const currentCropId = belongOrgs.find(org => org.level === 'corp')?.id;
      const userStore = useUserStore();
      if (currentCropId && currentCropId !== userStore.corpId) {
        await userStore.fetchCurrentCorpInfo(currentCropId);
      }
      this.setBelongOrgs(belongOrgs, locatedOrgReq.appKey);
    },
    /**
     * 获取同名组织架构
     * @param appId
     * @returns
     */
    async fetchSameOrgs(appId: MicroApp.AppKeyType) {
      const bizId = this.belongOrgs.find(org => org.level === 'biz')?.id;
      return fetchRecentBiz({
        corporationId: useUserStore().corpId as string,
        appKey: AppKeyMapping[appId],
        bizId,
        getSameBiz: !!bizId,
      });
    },
    /**
     * 启用空间切换组件
     * @param spaceSwitchOpts {EnableSpaceSwitchOpts}
     */
    enableSpaceSwitch(spaceSwitchOpts: EnableSpaceSwitchOpts | undefined): void {
      this.spaceSwitch.enable = true;
      if (!spaceSwitchOpts) return;
      if (!isNil(spaceSwitchOpts.bottomActions)) {
        this.spaceSwitch.bottomActions = spaceSwitchOpts.bottomActions;
      }
      if (!isNil(spaceSwitchOpts.canEntrySpaceSettings)) {
        this.spaceSwitch.canEntrySpaceSettings = spaceSwitchOpts.canEntrySpaceSettings;
      }
    },
    /**
     * 禁用空间切换组件
     */
    disableSpaceSwitch(): void {
      this.spaceSwitch.enable = false;
    },
  },
  // 自定义字段，标识该store是否被全局监听
  globalStore: true,
});
