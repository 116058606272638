/**
 * @description 基座【全局信息弹出层】插槽
 * @module types
 */
import { Ref, ref } from 'vue';
import eventCenter from '@/services/global-api/event/eventCenter';
import { EventType } from '@/services/global-api/modules/global-popup-slot';
import { useNavigationStore } from '@/stores/navigation';
import { useUserStore } from '@/stores/user';
import type { PluginConfig } from '@tencent/beacon-plugin';

// 全局弹出框显隐控制位
const visible = ref(false);
// 全局弹出框所应用的插件code
const pluginCode = ref('');
// 调用globalAPI打开全局弹出框时传入的业务参数
const globalPopupSlotOpenPayload = ref({});
// 调用globalAPI关闭全局弹出框时传入的业务参数
let globalPopupSlotClosePayload = {};
// 请求全局弹窗插件配置列表promise（用来保持只请求一次）
let getGlobalPopupPluginConfigListPromise: null | Promise<PluginConfig[]> = null;

// 插槽wrapper组件支持的组件类型
export enum GlobalPopupSlotWrapper {
  DRAWER = 'drawer',
  DIALOG = 'dialog'
}

export interface GlobalPopupWrapperComponentProps {
  // 弹出框标题，showTitle=true时生效
  title?: string;
  // 是否展示标题区域
  showTitle?: boolean;
  // 弹框宽度
  size?: number;
  // FIXME: 应导入dialog | drawer组件的类型声明
  [key: string]: any;
}

// globalAPI打开全局弹出框内置参数类型
export interface GlobalPopupSlotOpenOptions {
  code: string;
}

// globalAPI打开全局弹出框业务参数类型
export interface GlobalPopupSlotOpenPayload {
  [key: string]: any;
}

// globalAPI关闭全局弹出框业务参数类型
export interface GlobalPopupSlotClosePayload {
  [key: string]: any;
}

export const useGlobalPopupSlot = function useGlobalPopupSlot(): {
  visible: Ref<boolean>,
  pluginCode: Ref<string>,
  globalPopupSlotOpenPayload: Ref<GlobalPopupSlotOpenPayload>,
  open: (options: GlobalPopupSlotOpenOptions, payload: GlobalPopupSlotOpenPayload) => void,
  close: (payload: GlobalPopupSlotClosePayload) => void,
  setPayload: (payload: GlobalPopupSlotOpenPayload) => void,
  handleOpened: (payload: GlobalPopupSlotOpenPayload) => void,
  handleClosed: (payload: GlobalPopupSlotClosePayload) => void,
  getGlobalPopupPluginConfigList: () => Promise<PluginConfig[] | null>
} {
  /**
   * @description 打开全局弹出框
   * @param options 内置参数
   * @param payload 业务参数
   */
  const open = (
    options: GlobalPopupSlotOpenOptions,
    payload: GlobalPopupSlotOpenPayload,
  ): void => {
    if (!visible.value) {
      // 修改弹窗显隐控制标志
      visible.value = true;
      // 更新业务参数
      initProps(options, payload);
    } else {
      throw new Error(`[${options.code}]打开失败，全局弹出框处于打开状态`);
    }
  };

  /**
   * @description 关闭全局弹出框
   * @param payload 业务参数
   */
  const close = function close(payload: GlobalPopupSlotClosePayload): void {
    // 修改弹窗显隐控制标志
    visible.value = false;
    // 更新业务参数
    globalPopupSlotClosePayload = payload;
  };

  /**
   * @description 全局弹出框打开后，异步更新payload
   * @param payload 业务参数
   */
  const setPayload = function setPayload(payload: GlobalPopupSlotOpenPayload): void {
    globalPopupSlotOpenPayload.value = payload;
  };

  /**
   * @description 打开全局弹出层框回调
   */
  const handleOpened = function handleOpened(): void {
    eventCenter.dispatch(EventType.OPENED, {
      code: pluginCode.value,
    });
  };

  /**
   * @description 关闭全局弹出层回调
   */
  const handleClosed = function handleClosed(): void {
    eventCenter.dispatch(EventType.CLOSED, {
      ...globalPopupSlotClosePayload,
    });
    // 在回调中清理状态
    resetProps();
  };

  /**
   * @description 打开时，装配参数
   */
  const initProps = function initProps(
    options: GlobalPopupSlotOpenOptions,
    payload: GlobalPopupSlotOpenPayload,
  ): void {
    const { code } = options;
    pluginCode.value = code;
    globalPopupSlotOpenPayload.value = payload;
  };

  /**
   * @description 关闭时，还原参数
   */
  const resetProps = function resetProps(): void {
    pluginCode.value = '';
    globalPopupSlotClosePayload = {};
    // 清理事件
    eventCenter.clear(EventType.OPENED);
    eventCenter.clear(EventType.CLOSED);
  };

  /**
   * @description 获取全局弹出框所有插件配置列表
   */
  const getGlobalPopupPluginConfigList = async (): Promise<PluginConfig[] | null> => {
    if (!getGlobalPopupPluginConfigListPromise) {
      // 首次打开弹窗或者上次请求出错都走这里重新拉取
      getGlobalPopupPluginConfigListPromise = await window.BeaconPlugin.load(
        useNavigationStore().getCurrentBizId,
        {
          corpId: useUserStore().corpId,
          group: 'GlobalPopup',
        },
      );
    }
    return getGlobalPopupPluginConfigListPromise;
  };

  return {
    visible,
    pluginCode,
    globalPopupSlotOpenPayload,
    open,
    close,
    setPayload,
    handleOpened,
    handleClosed,
    getGlobalPopupPluginConfigList,
  };
};
