/**
 * @description 转换 AppID
 * @module global-api
 */

import { AppKeyMapping, BEAppKeyType } from '@/constants/micro';

/**
 * 将前端 AppId 或 后端 AppId 转换清楚
 * @param anyAppKey {MicroApp.AppKeyType | BEAppKeyType}
 * @returns
 */
export default function transformAppKey(anyAppKey: MicroApp.AppKeyType | BEAppKeyType): {
  appKey: MicroApp.AppKeyType,
  beAppKey: BEAppKeyType
} {
  let appKey = anyAppKey as MicroApp.AppKeyType;
  // 获取前端子应用 ID 对应的后端 ID
  let beAppKey = AppKeyMapping[appKey];
  if (!beAppKey) {
    const beAppKeys =  Object.values(AppKeyMapping);
    // 继续判断是否是后端 ID
    const ifBEId = beAppKeys.includes(anyAppKey as BEAppKeyType);
    if (!ifBEId) throw new Error(`GlobalAPI 传入的参数 appId 异常：${anyAppKey}`);
    beAppKey = anyAppKey as BEAppKeyType;
    const scorpFeAppId = Object.keys(AppKeyMapping)
      .find(scopeFeAppKey => beAppKey === AppKeyMapping[scopeFeAppKey as MicroApp.AppKeyType]);
    if (!scorpFeAppId) throw new Error(`GlobalAPI 传入的参数 appId 异常：${anyAppKey}`);
    appKey = scorpFeAppId as MicroApp.AppKeyType;
  }
  return {
    appKey,
    beAppKey,
  };
}
