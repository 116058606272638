/**
 * @description 微前端状态 Store
 * @module store
 */
import type { NavigationFailure } from 'vue-router';
import { defineStore } from 'pinia';
import { get } from 'lodash-es';
import globalAPI from '@/services/global-api';
import $router from '@/router';
import { AppKeyMapping } from '@/constants/micro';
import { ORG_LEVELES, RecentOrgUnit } from '@/types/apis/organisation';
import { fetchLoginTicket } from '@/services/apis/user';
import { appendParamsUrl } from '@/utils/append-params-url';
import { setCorsCookie } from '@/utils/set-cors-cookie';

export interface MicroAppStoreState {
  // 当前子应用
  currentAppId: MicroApp.AppKeyType;
  // 子应用列表
  allApps: MicroApp.AppOpts[];
  // 子应用 map
  appMap: Partial<Record<MicroApp.AppKeyType, MicroApp.AppOpts>>
}

export const useMicroAppStore = defineStore('microapp', {
  state: (): MicroAppStoreState => ({
    currentAppId: 'console',
    allApps: [],
    appMap: {},
  }),
  getters: {
    currentAppIdBE: state => AppKeyMapping[state.currentAppId],
    currentApp: state => state.allApps.find(app => app.id === state.currentAppId),
  },
  actions: {
    /**
     * 初始化微前端 App 配置，在 index.html 调用的后端接口返回的数据，在 Vue 实例化时设置
     */
    initMicroAppConfigs(apps: MicroApp.AppOpts[]) {
      this.allApps = apps;
      this.allApps.forEach((app) => {
        this.appMap[app.id] = app;
      });
    },
    /**
     * 进入子应用
     * @param appKey {AppKeyType} 子应用名
     */
    async enterSubApp(appKey: MicroApp.AppKeyType) {
      if (!this.appMap[appKey]) return;
      this.currentAppId = appKey;
      if (!this.currentApp?.loginTicket || !this.currentApp?.url) return;
      // 处理 请求子应用 index.html 需要添加 loginTicket 的情况，例如 saas wuji
      const loginTicket = await fetchLoginTicket();
      const url = appendParamsUrl(this.currentApp.url, {
        name: 'login_ticket',
        value: loginTicket,
        override: true,
      });
      await setCorsCookie(url);
    },
    /**
     * 获取子应用信息
     * @param appKey
     */
    getAppConfig(appKey: MicroApp.AppKeyType): MicroApp.AppOpts | undefined {
      return this.allApps.find(app => app.id === appKey);
    },
    /**
     * 构建 URL 通过组织架构
     * @param url
     * @param belongOrgs
     * @returns
     */
    buildOrgsUrl(url: string, belongOrgs: RecentOrgUnit[]) {
      let finnallyLandUrl = url;
      belongOrgs.forEach((orgLevel) => {
        finnallyLandUrl = finnallyLandUrl.replace(`\${${orgLevel.level}}`, orgLevel.appOrgId || orgLevel.id);
      });
      return finnallyLandUrl;
    },
    /**
     * 构造应用落地页 URL
     * @param appName {MicroApp.AppKeyType}
     * @param belongOrgs {RecentOrgUnit[]}
     * @returns {string | undefined}
     */
    buildLandPageUrl(appName: MicroApp.AppKeyType, belongOrgs?: RecentOrgUnit[]): string {
      const currentApp =  this.allApps.find(app => app.id === appName);
      if (!currentApp || !currentApp.landPageUrl) return '';
      const { landPageUrl } = currentApp;
      // 落地页路径是否包含组织信息
      const hasOrg = ORG_LEVELES.some(level => new RegExp(`\\$\{${level}}`).test(landPageUrl));
      // 不包含组织信息的路径直接返回
      if (!hasOrg) return landPageUrl;
      // 包含组织信息的路径且必须存在组织信息
      if (belongOrgs?.length) {
        return this.buildOrgsUrl(landPageUrl, belongOrgs);
      }
      return '';
    },
    /**
     * 进入当前子应用落地页
     * @param belongOrgs {RecentOrgUnit[]}
     */
    enterCurrentAppLandPage(belongOrgs: RecentOrgUnit[]) {
      const landPageUrl = this.buildLandPageUrl(this.currentAppId, belongOrgs);
      globalAPI.router.routerPush({ path: landPageUrl, eventName: 'OrgnisationChange' });
    },
    /**
     * 获取子应用不包含的公共模块
     * @param appKey
     * @returns
     */
    getAppExcludesModuleKeys(appKey: MicroApp.AppKeyType): MicroApp.AppKeyType[] {
      const currentAppConfig = this.getAppConfig(appKey);
      return get(currentAppConfig, 'sidebar.excludesModule', []);
    },
    /**
     * 在主应用内进入到子应用
     * @param appKey
     * @param url
     * @returns
     */
    routerToApp(url: string): Promise<NavigationFailure | void | undefined> {
      if (!$router.instance) return Promise.resolve();
      // 是否进入子应用时刷新页面，离开需要刷新页面的子应用
      // 用于子应用内嵌套子应用修改错误的 history 导致 bug 的问题
      if (this.currentApp?.entryRefresh) {
        const resolveUrl = $router.instance.resolve(url);
        window.location.href = resolveUrl.fullPath;
        return Promise.resolve();
      }
      return $router.instance.push(url);
    },
  },
});
