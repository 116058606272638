/**
 * @description 用户模块
 * @module global-api
 */

import { BEAppKeyType } from '@/constants/micro';
import { fetchBusinessInfo, fetchRecentBiz } from '@/services/apis/organisation';
import { reportVisitData } from '@/services/apis/user';
import { useMicroAppStore } from '@/stores/micro-app';
import { UserStoreState, useUserStore } from '@/stores/user';
import { useNavigationStore } from '@/stores/navigation';
import { BusinessInfo, RecentOrgUnit } from '@/types/apis/organisation';
import type { ReportUserVisitDataParams, UserInfo } from '@/types/apis/user';
import type { IResponse } from '@suite/shared';
import { cloneDeep } from 'lodash-es';
import type { EventCenterObserverCallback } from '../index.types';
import transformAppKey from '../utils/transform-app-key';
import eventCenter from '../event/eventCenter';
import { IAnnoucementConfig, useAnnoucement } from '@/views/micro-layout/navigation-announce/use-annoucement';

export interface UserModuleAPI {
  // 监听用户信息变化
  onStateChange(callback: EventCenterObserverCallback): void;
  // 获取用户信息
  getState(): UserStoreState;
  // 获取当前业务信息
  getBusiInfo(busiId?: string): Promise<BusinessInfo>;
  // 上报用户访问数据
  reportUserVisitData(params: ReportUserVisitDataParams): Promise<IResponse>;
  // 获取当前产品用户最近访问的空间的 URL
  fetchSubAppUrlByUserRecent(appKey: MicroApp.AppKeyType | BEAppKeyType): Promise<{
    path: string;
    fullPath: string;
    recentOrgs: RecentOrgUnit[]
  }>;
  // 更新当前用户信息，在 DataAuth 登陆完成后调用
  updateUser(): Promise<UserInfo>;
  // 更新当前用户选择的租户信息，在 DataAuth 重新选择租户后调用
  updateCorp(corpId: string): Promise<void>;
  // 打开公告
  openAnnouncement(announcement: IAnnoucementConfig): void;
  // 重置公告，子应用只需要关闭，而不是记忆性的close，保证下次打开相同id的公告还能生效
  resetAnnoucement(): void;
}

const userAPI: UserModuleAPI = {
  onStateChange(callback): void {
    eventCenter.on('userStore', callback);
    const userStore = useUserStore();
    eventCenter.dispatch('userStore', cloneDeep(userStore.$state));
  },
  getState() {
    const userStore = useUserStore();
    return cloneDeep(userStore.$state);
  },
  getBusiInfo(currentBusiId) {
    const busiId = currentBusiId || useNavigationStore().belongOrgs.find(org => org.level === 'business')?.id;
    if (!busiId) {
      console.log('userAPI.getBusiInfo 缺少busiId');
      return Promise.reject('userAPI.getBusiInfo 缺少busiId');
    }
    return fetchBusinessInfo(busiId);
  },
  reportUserVisitData(params) {
    const userStore = useUserStore();
    if (!userStore.corpId) {
      console.log('userAPI.reportUserVisitData 企业信息不存在');
      return Promise.reject();
    }
    return reportVisitData(Object.assign(params, {
      appKey: useMicroAppStore().currentAppIdBE,
      dataUrl: location.href.replace(location.protocol, ''),
      pathName: location.href.replace(location.origin, ''),
    }));
  },
  fetchSubAppUrlByUserRecent: async (anyAppKey) =>  {
    const { appKey, beAppKey } = transformAppKey(anyAppKey);
    const userStore = useUserStore();
    if (!userStore.corpId) {
      console.log('userAPI.fetchSubAppUrlByUserRecent 企业信息不存在');
      return Promise.reject();
    }
    return fetchRecentBiz({
      corporationId: userStore.corpId,
      appKey: beAppKey,
    }).then((recentOrgs) => {
      const microAppStore = useMicroAppStore();
      const path = microAppStore.buildLandPageUrl(appKey, recentOrgs);
      const appConfig = microAppStore.getAppConfig(appKey);
      if (!appConfig || !appConfig.baseroute) throw new Error(`GlobalAPI fetchSubAppUrlByUserRecent 传入的参数 appKey 异常：${appKey}`);
      return {
        path,
        fullPath: `${appConfig.baseroute}${path}`,
        recentOrgs,
      };
    });
  },
  updateUser() {
    return useUserStore().fetchUserInfo();
  },
  updateCorp(corpId: string) {
    return useUserStore().fetchCurrentCorpInfo(corpId);
  },
  openAnnouncement(announcement) {
    useAnnoucement().openAnnoucement(announcement);
  },
  resetAnnoucement() {
    useAnnoucement().resetAnnoucement();
  },
};

export default userAPI;
