/**
 * @description 基座主动触发子应用的路由更改
 * @module global-api
 */
import { useMicroAppStore } from '@/stores/micro-app';
import eventCenter from '../event/eventCenter';
import type {
  RouterModuleAPI,
  EventCenterObserverCallback,
  RouterModuleParams,
  RouterQuery,
} from '../index.types';
import type { RouteLocationRaw } from 'vue-router';
import $router from '@/router';
import queryString from 'query-string';

// 转换路由参数
const genParams = (appName: MicroApp.AppKeyType, params: RouteLocationRaw): RouteLocationRaw => {
  const prePath = `/${appName}`;
  if (typeof params === 'string') {
    const { url, query } = queryString.parseUrl(params, { decode: false });
    return {
      path: prePath + url,
      query,
    };
  }

  return {
    ...params,
    path: prePath + (params as RouterQuery).path,
  };
};

enum RouterEventType {
  // 与 StoreID 对应
  Action = 'router:action',
}
class RouterModule implements RouterModuleAPI {
  /**
   * 监听事件, 一般只供给子应用使用
   * @param cb 回调
   */
  public addListener(cb: EventCenterObserverCallback): void {
    eventCenter.on(RouterEventType.Action, (...args) => {
      console.log('当前激活的 App', useMicroAppStore().currentAppId, args);
      cb(...args);
    });
  }

  /**
   * 触发 push 路由
   * @param params 路由参数
   */
  public routerPush(params: RouterModuleParams): void {
    this.dispatchRouterChange('push', params);
  }

  /**
   * 触发 replace 路由
   * @param params 路由参数
   */
  public routerReplace(params: RouterModuleParams): void {
    this.dispatchRouterChange('replace', params);
  }

  /**
   * push方式切换子应用
   * @param appName {string} 子应用的appName
   * @param params {RouteLocationRaw} 路由参数
  */
  public subAppPush(appName: MicroApp.AppKeyType, params: RouteLocationRaw): void {
    const options = genParams(appName, params);
    $router.instance?.push(options);
  }

  /**
   * replace方式切换子应用
   * @param appName {string} 子应用的appName
   * @param params {string | RouteLocationRaw} 路由参数
  */
  public subAppReplace(appName: MicroApp.AppKeyType, params: RouteLocationRaw): void {
    const options = genParams(appName, params);
    $router.instance?.replace(options);
  }

  private dispatchRouterChange(
    type: 'push'|'replace',
    params: RouterModuleParams,
  ): void {
    eventCenter.dispatch(RouterEventType.Action, {
      type,
      ...params,
    });
  }
}
const routerAPI = new RouterModule();

export default routerAPI;

