/**
 * @description 提供给子应用的常用工具
 * @module global-api
 */
import { http } from '@suite/shared';
import jsonp from '@/assets/js/jsonp.js';

type CancelFn = () => void;
type RequestCallback = (error: Error | null, data: unknown) => void;

interface Options {
  param?: string | undefined;
  prefix?: string | undefined;
  name?: string | undefined;
  timeout?: number | undefined;
}

export interface UtilsAPI {
  jsonp: (url: string, options?: Options | undefined, cb?: RequestCallback | undefined) => CancelFn
  | ((url: string, callback?: RequestCallback | undefined) => CancelFn);
  // 获取应用 title
  getAppTitle: () => {
    // 完整页面 title
    full: string;
    // 简短 title，用于-子应用页面：(子应用自定义前缀 -) 子应用名称 ｜ <simple>
    simple: string;
  };
  // 获取遵循腾讯 sql 规范的格式化后的 sql
  fetchFormattedSQL: (sqlString: string) => Promise<string>;
}

const utilsAPI: UtilsAPI = {
  jsonp,
  getAppTitle() {
    return {
      full: process.env.VUE_APP_DOCUMENT_TITLE || '腾讯灯塔 · 全场景分析套件',
      simple: '腾讯灯塔',
    };
  },
  fetchFormattedSQL(sqlString: string): Promise<string> {
    // 获取遵循腾讯 sql 规范的格式化后的 sql. 若接口报错，不弹窗
    return http.post('/api/suiteserver/web/sql/format', { sqlString }, { disabledErrorTip: true })
      .then(res => res.data)
      .catch(() => '');
  },
};

export default utilsAPI;
