/**
 * @description JS 注入插件
 * @module entry
 */

import type { GlobalAPI } from '@/services/global-api/index.types';

type InjectJSPluginSource = {
  injectJS: () => Promise<void>
} | undefined;

export const injectJS = async function injectJS(): Promise<void | {
  patchGlobalAPI: (params: GlobalAPI) => GlobalAPI
}> {
  await window.SuitePreFetch?.getFrontendCommonConfig;
  // 必须等到配置返回后再执行（当未触发 localstorage 内的缓存时，重新请求耗时在执行该逻辑之后，window.FrontConfig 为 null 将导致异常）
  if (!window.FrontConfig.plugins?.jsInject?.code) return;
  try {
    const { code, libraryKey } = window.FrontConfig.plugins?.jsInject;
    const pluginSource: InjectJSPluginSource = await window.BeaconPlugin
      .import(code, {
        libraryKey,
      });
    if (!pluginSource) return;
    return pluginSource.injectJS();
  } catch (e) {
    console.warn('[Plugin] run injectJS error', e);
  }
  return;
};
